<template>
  <div>
    <client-only>
      <!-- <h4 class="mt-4 mb-2 fs-14">Imprint Method</h4>
      <v-row no-gutters>
        <v-col cols="12" v-for="(item, i) in Imports" :key="i">
          <div class="d-inline-flex">
            <v-checkbox
              v-model="form.imports"
              @change="search"
              :label="item.nameEn"
              :value="item.id"
              multiple
              hide-details
              class="fc-666 fb-650 fs-14"
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>-->

      <h4 class="mt-4 mb-2 fs-14">Price Range</h4>
      <p class="tips fs-12 my-3">Different prices for different quantities</p>

      <v-row no-gutters class="price">
        <v-col cols="5">
          <v-text-field
            v-model.trim="form.priceMin"
            @blur="priceChange('min', form.priceMin)"
            label="Min"
            prepend-inner-icon="mdi-currency-usd"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="text-center symbol">~</v-col>
        <v-col cols="5">
          <v-text-field
            v-model.trim="form.priceMax"
            @blur="priceChange('max', form.priceMax)"
            label="Max"
            prepend-inner-icon="mdi-currency-usd"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        v-model.trim="form.quantity"
        @blur="quantitySearch(form.quantity)"
        oninput="value=value.replace(/[^\d]/g,'')"
        label="Estimated Quantity"
        class="mt-5"
        hide-details
      ></v-text-field>
      <v-btn
        @click="reset"
        class="w-100 mt-5"
        variant="outlined"
        color="primary"
        :loading="loading"
        v-if="showReset"
        >Reset All Options</v-btn
      >
    </client-only>
  </div>
</template>

<script setup>
import { productInteractor } from "~/core";
import { useProductStore } from "~/stores";

const emit = defineEmits(["checkboxFn", "searchQuery"]);
const route = useRoute();
const router = useRouter();
const productStore = useProductStore();
const props = defineProps({
  showReset: {
    type: Boolean,
    default: true
  }
});
const form = ref({
  imports: [], // 印刷方式
  importsName: "", // 印刷方式名称，只用作url展示
  priceMin: "", // 产品价格Min
  priceMax: "", // 产品价格Max
  quantity: "" // 数量
});
const loading = ref(false);
const Imports = ref([]);

/**
 * 获取印刷方式
 */
const getImprintMethod = async () => {
  const [res] = await productInteractor.printingWay();

  Imports.value = res;
  productStore.setImprintMethod(res);
  echoQuery();
};

// 重置
const reset = () => {
  loading.value = true;
  form.value = {
    imports: [],
    importsName: "",
    priceMin: "",
    priceMax: "",
    quantity: ""
  };
  search();

  setTimeout(() => {
    loading.value = false;
  }, 500);
};

// 监听搜索条件
const search = () => {
  searchQuery(handleQuery());
};

// url添加请求参数
const searchQuery = query => {
  let params = {};

  if (route.query.keyword) {
    params.keyword = route.query.keyword;
  }
  if (query.importsName) {
    params.importsName = query.importsName;
  }
  if (query.priceMin) {
    params.priceMin = query.priceMin;
  }
  if (query.priceMax) {
    params.priceMax = query.priceMax;
  }
  if (query.quantity) {
    params.quantity = query.quantity;
  }
  if (route.query.sortBy) {
    params.sortBy = route.query.sortBy;
  }
  if (route.query.page) {
    params.page = 1;
  }

  router.push({
    query: {
      ...params
    }
  });
};

// url印刷方式请求参数处理
const handleQuery = () => {
  const imports = form.value.imports;

  form.value.importsName = [];
  if (imports.length) {
    for (let i = 0; i < Imports.value.length; i++) {
      if (imports.includes(Imports.value[i].id)) {
        form.value.importsName.push(Imports.value[i].nameEn);
      }
    }
    form.value.importsName = form.value.importsName.join("-");
  }
  return form.value;
};

// 回显筛选条件
const echoQuery = () => {
  const query = route.query;

  if (query?.importsName) {
    if (Array.isArray(query.importsName)) return false;

    const importsName = query.importsName?.split("-");
    if (!importsName) return false;

    form.value.imports = [];
    for (let i = 0; i < Imports.value.length; i++) {
      if (importsName.includes(Imports.value[i].nameEn)) {
        form.value.imports.push(Imports.value[i].id);
      }
    }
  } else {
    form.value.imports = [];
    form.value.importsName = "";
  }
  if (query.priceMin) {
    form.value.priceMin = query.priceMin;
  } else {
    form.value.priceMin = "";
  }
  if (query.priceMax) {
    form.value.priceMax = query.priceMax;
  } else {
    form.value.priceMax = "";
  }
  if (query.quantity) {
    form.value.quantity = query.quantity;
  } else {
    form.value.quantity = "";
  }
};

/**
 * 价格区间校验
 * @param {opt}
 */
const priceChange = (opt, val) => {
  // 仅支持数字和小数
  if (!(/^\d+\.\d+$/.test(val) || /^\d+$/.test(val))) {
    opt === "min" ? (form.value.priceMin = "") : (form.value.priceMax = "");
  }

  const [min, max] = [Number(form.value.priceMin), Number(form.value.priceMax)];
  if (opt === "min") {
    if (max > 0 && min > max && min) {
      form.value.priceMin = "";
    }
  } else {
    if (min > 0 && min > max && max) {
      form.value.priceMin = "";
    }
  }

  search();
};

// 输入数量搜索
const quantitySearch = val => {
  form.value.quantity = val?.toString()?.replace(/[^\d]/g, "");
  search();
};

watch(
  () => route.query,
  query => {
    if (query.keyword && Object.keys(query).length === 1) {
      form.value.priceMin = "";
      form.value.priceMax = "";
      form.value.quantity = "";
      form.value.imports = [];
      form.value.importsName = "";
    }
    echoQuery();
  },
  { immediate: true }
);

onMounted(() => {
  getImprintMethod();
});

defineExpose({
  reset,
  search
});
</script>

<style lang="scss" scoped>
.tips {
  background: #ffffc0;
  color: rgba(217, 0, 27, 0.6);
  padding: 2px 5px;
}
.symbol {
  line-height: 40px;
}
.price {
  :deep(.v-field) {
    padding-right: 0;
  }
  :deep(.v-field__input) {
    padding: 0;
  }
}
:deep(.price .mdi) {
  font-size: 16px;
  color: #000;
  opacity: 1;
}
</style>
